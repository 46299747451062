import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-attendance-info-dialog',
  templateUrl: './attendance-info.component.html',
  styleUrls: ['./attendance-info.component.scss']
})
export class AttendanceInfoDialogComponent implements OnInit {

  displayedColumns: string[] = ['period', 'present', 'absent', 'leave'];
  dataSource = new MatTableDataSource<any>();
  attendanceTotals: { [periodId: string]: { present: number, absent: number, leave: number } } = {};
  periodNameByIdInverted: { [id: string]: string } = {};

  constructor(
    public dialogRef: MatDialogRef<AttendanceInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { attendanceData: Array<Record<string, any>>, periodNameById: { [key: string]: string } }
  ) { }

  ngOnInit(): void {
    this.invertPeriodNameById();
    this.calculateAttendanceTotals();
    this.updateDataSource();    
  }

  invertPeriodNameById(): void {
    this.periodNameByIdInverted = {};
    Object.keys(this.data.periodNameById).forEach(periodName => {
      const periodId = this.data.periodNameById[periodName];
      this.periodNameByIdInverted[periodId] = periodName.trim();
    });
    console.log("Inverted Period Name By ID:", this.periodNameByIdInverted);
  }

  calculateAttendanceTotals(): void {
    this.attendanceTotals = {};
    this.data.attendanceData.forEach(record => {
      Object.keys(record).forEach(periodId => {
        if (!isNaN(parseInt(periodId))) {
          if (!this.attendanceTotals[periodId]) {
            this.attendanceTotals[periodId] = { present: 0, absent: 0, leave: 0 };
          }
          switch (record[periodId].attendance) {
            case 'present':
              this.attendanceTotals[periodId].present++;
              break;
            case 'absent':
              this.attendanceTotals[periodId].absent++;
              break;
            case 'leave':
              this.attendanceTotals[periodId].leave++;
              break;
          }
        }
      });
    });
  }

  updateDataSource(): void {

    const tableData = Object.keys(this.attendanceTotals).map(periodId => {
      const periodName = this.periodNameByIdInverted[periodId.trim()];

      if (!periodName) {
        console.warn(`No period name found for ID: ${periodId.trim()}`);
      }

      return {
        period: periodName || `Unknown Period (${periodId.trim()})`,
        ...this.attendanceTotals[periodId.trim()]
      };
    });
    this.dataSource.data = tableData;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
