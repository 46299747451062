import { Platform } from '@angular/cdk/platform';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UnsubscribeOnDestroyAdapter } from 'src/app/shared/UnsubscribeOnDestroyAdapter';
import { academicYearList } from 'src/app/shared/services/academicyear';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';
import { Device } from '@capacitor/device';
@Injectable({ providedIn: 'root' })
export class CommonService extends UnsubscribeOnDestroyAdapter {
  private readonly API_URL = environment.apiUrl;
  academic_year: BehaviorSubject<any> = new BehaviorSubject<any>({});
  class: BehaviorSubject<any> = new BehaviorSubject<any>({});
  academic_year_list: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  academicYeardata = academicYearList();
  permissionEnabled: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  previous_url = '';

  constructor(private http: HttpClient, private authService: AuthService, private platform: Platform) {
    super();
    if (this.authService.currentUserValue) {
      this.getAllAcademicYears();
    }
  }

  get getAcademicYearList() {
    return this.academic_year_list.asObservable();
  }

  get getAcademicYear() {
    return this.academic_year.asObservable();
  }

  get getPermission() {
    return this.permissionEnabled.asObservable();
  }

  get isCollege() {
    return this.authService.currentUserSubject.value.entitytype.toLowerCase() === 'college';
  }

  get isMobileDevice(): boolean {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }

  get getClass() {
    return this.class.asObservable();
  }

  setAcademicYear(value: any) {
    this.academic_year.next(value);
  }

  setClass(value: any) {
    this.class.next(value);
  }

  setPermission(value: any) {
    this.permissionEnabled.next(value);
  }

  async getLoginUserDetails() {
    const loginUserDetails: any = {
      "school_id": this.authService.currentUserSubject?.value?.school_id,
      "id": this.authService.currentUserSubject?.value?.id,
      "type": this.authService.currentUserSubject?.value?.role,
      "academic_year": this.authService.currentUserSubject?.value?.academic_year,
      "class_id": this.authService.currentUserSubject?.value?.class_id,
      'firebaseId': localStorage.getItem('firebaseToken'),
    };
    if (this.platform.isBrowser && !this.platform.ANDROID && !this.platform.IOS) {
      loginUserDetails.deviceName = window.navigator.userAgent
    } else if (this.platform.ANDROID) {
      loginUserDetails.deviceName = await this.getDeviceName();
    } else if (this.platform.IOS) {
      loginUserDetails.deviceName = await this.getDeviceName();
    }
    console.log('in')
    if (this.authService.currentUserSubject?.value?.id) {
      const url = this.API_URL + 'api/logininfo/userdetails';
      this.http.post<any>(url, loginUserDetails)
        .subscribe((result: any) => {
          if (result.length) {
            result[0].role = this.authService.currentUserSubject?.value?.role;
            result[0].academic_year = this.authService.currentUserSubject?.value?.academic_year;
            result[0].class_id = this.authService.currentUserSubject?.value?.class_id;
            localStorage.setItem('currentUser', JSON.stringify(result[0]));
            this.authService.currentUserSubject.next(result[0]);
          }
        });
    }
  }

  async getDeviceName() {
    const info = await Device.getInfo();
    return info.name;
  }

  getAllAcademicYears() {
    if (this.authService.currentUserSubject?.value?.school_id) {
      this.http.get(`${environment.apiUrl}api/academicyearinfo/academicyear/${this.authService.currentUserSubject.value.school_id}`).subscribe((result: any) => {
        const academicRange = this.academicYeardata[0].value.split('-');
        this.academic_year_list.next(result);
        let index = result.length - 1;
        for (let i = 0; i < result.length; i++) {
          if (result[i].academic_year.includes(academicRange[0]) && result[i].academic_year.includes(academicRange[1])) {
            index = i;
            break;
          }
        }
        this.assignAcademicYear(result, index);
      });
    }
  }

  assignAcademicYear(result: any, index: number) {
    this.setAcademicYear({ selectedYear: result[index].id, currentYear: result[index].id });
    this.authService.currentUserSubject.value.academic_year = result[index].id;
    console.log(this.authService.currentUserSubject.value.academic_year)
    this.getLoginUserDetails();
  }

  getGeolcation(params: any): Observable<any> {
    const url = `https://apis.mapmyindia.com/advancedmaps/v1/d10d4c1b972441081b588ffc5eac419f/rev_geocode?lat=${params.lat}&lng=${params.lng}`;
    return this.http.get<any>(url);
  }

  removeAccount(body: any) {
    const url = this.API_URL + 'api/logininfo/deleteaccount';
    return this.http.post(url, body);
  }

  isMobile(): boolean {
    return this.platform.ANDROID || this.platform.IOS
  }

  getSchoolDetails() {
    return this.http.get(`${this.API_URL}api/schoolinfo/schools/${this.authService.currentUserValue.school_id}`);
  }

  getEntityTheme(){
    const url = `${this.API_URL}api/entityThemeColourInfo/entityThemeColours/${this.authService.currentUserValue.school_id}`;    
    return this.http.get(url);
  }
  setEntityTheme(header?: any, sidebar?: any){
    const url = `${this.API_URL}api/entityThemeColourInfo/entityThemeColours`;      
      const body = { 
        schoolId: this.authService.currentUserValue.school_id,
        header: header,
        sidebar: sidebar,
       };
      this.http.put(url, body).subscribe((response: any) => {
        return response;
      }, (error: any) => {
        console.error('API error:', error);
      });
  }
  downloadFile(url: string) {
    const filename = url.split('/').pop()?.split('?')[0] ?? ''
    this.http.get(url, { responseType: 'blob' }).subscribe(blob => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = filename;
      a.click();
      URL.revokeObjectURL(objectUrl);
    });
  }

  generateUniqueTransactionId() {
    const timestamp = Date.now().toString(); // Get the current timestamp, e.g., "1656427854321"
    const randomPart = Math.floor(100000 + Math.random() * 900000).toString(); // Generate a random 6-digit number, e.g., "283475"
    const uniqueId = timestamp.slice(-4) + randomPart.slice(0, 2); // Combine parts to form a 6-digit ID, e.g., "432128"

    return uniqueId;
}


}
