import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/api.service';
import { LocationSelectorComponent } from 'src/app/shared/components/location-selector/location-selector.component';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ConfigService } from 'src/app/shared/services/config.service';
import { DirectionService } from '../../../../services/direction.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
})
export class MainLayoutComponent {
  direction: any;
  config: any = {};

  constructor(
    private directoryService: DirectionService,
    public authService: AuthService,
    private service: ApiService,
    private configService: ConfigService,
    public dialog: MatDialog
  ) {
    this.config = this.configService.configData;
    this.directoryService.currentData.subscribe((currentData) => {
      if (currentData) {
        this.direction = currentData;
      } else {
        const isRtl = localStorage.getItem('isRtl');
        if (isRtl) {
          this.direction = isRtl === 'true' ? 'rtl' : isRtl === 'false' ? 'ltr' : this.direction;
        } else {
          this.direction = this.config.layout.rtl ? 'rtl' : 'ltr';
        }
      }
    });
    if (this.authService.currentUserValue.role === 'Student' && this.authService.currentUserValue.boarding_point?.data[0]?.toString() == 1) {
      this.getStudentLocation();
    }
  }

  getStudentLocation(): void {
    this.service.get('api/businfo/studentboardinglocation/' + this.authService.currentUserValue.id).subscribe((data: any) => {
      if (data != null && data.length == 0) {
        this.locationSelectorPopUp();
      }
    }, (error: any) => {
      this.service.showNotification('snackbar-danger', 'Error!!!');
    });
  }

  locationSelectorPopUp() {
    const dialogRef = this.dialog.open(LocationSelectorComponent, {
      width: '100%',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((result) => {
      /** */
    });
  }
}
