import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { environment } from '../../environments/environment';
import { FeatherIconsModule } from './components/feather-icons/feather-icons.module';
import { MaterialModule } from './modules/material.module';
import { BoardToUniversityPipe } from './services/boardtouniversity.pipe';
import { DefaultImagePipe } from './services/defaultimage.pipe';
import { HomeWorkToAssignmentPipe } from './services/homeworktoassignment.pipe';
import { HouseToClubPipe } from './services/housetoclub.pipe';
import { NumberToWordsPipe } from './services/numbertowords.pipe';
import { RollToUsnPipe } from './services/rollnumbertousnnumber.pipe';
import { SchoolToCollegePipe } from './services/schooltocollege.pipe';
import { TeacherToProfessorPipe } from './services/teachertoprofessor.pipe';

const DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD HH:mm:ss',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'YYYY-MM-DD HH:mm:ss',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    DefaultImagePipe,
    SchoolToCollegePipe,
    TeacherToProfessorPipe,
    HouseToClubPipe,
    HomeWorkToAssignmentPipe,
    BoardToUniversityPipe,
    NumberToWordsPipe,
    RollToUsnPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FeatherIconsModule,
    AngularFireStorageModule,
    AngularFireModule.initializeApp(environment.firebase, 'cloud'),
    AngularFireDatabaseModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    FeatherIconsModule,
    DefaultImagePipe,
    SchoolToCollegePipe,
    TeacherToProfessorPipe,
    HouseToClubPipe,
    HomeWorkToAssignmentPipe,
    BoardToUniversityPipe,
    NumberToWordsPipe,
    RollToUsnPipe,
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ],
})
export class SharedModule { }
