import { ConfigService } from '../../../services/config.service';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, ElementRef, OnInit, Renderer2, AfterViewInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { Role } from '../../../models/role';
import { LanguageService } from '../../../services/language.service';
import { UnsubscribeOnDestroyAdapter } from 'src/app/shared/UnsubscribeOnDestroyAdapter';
import { ApiService } from 'src/app/api.service';
import { NotificationService } from '../../../services/notification.service';
import { CommonService } from '../../../../shared/services/common.service';
import { DirectionService } from 'src/app/shared/services/direction.service';
import moment from 'moment';

const document: any = window.document;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends UnsubscribeOnDestroyAdapter implements OnInit, AfterViewInit {
  public config: any = {};
  userImg: string;
  userName: string;
  homePage: string;
  logoImage: string;
  schoolName: string;
  department_name: string;
  isNavbarCollapsed = true;
  flagvalue: any;
  countryName: any;
  langStoreValue: string;
  defaultFlag: string;
  isOpenSidebar: boolean;
  selectedIndex = 0;
  userType: Role;
  academic_years: any = [];
  selectedAcademic: any;
  selectedClass: any;
  classes: any;
  currentAcademic: any;
  appVersion: string
  ddFields = { text: 'academic_year', value: 'id' };
  ccFields = {text: 'class_name' , value: 'class_id'};
  entityType: any;
  listLang: Array<any> = [
    { text: 'English', flag: 'assets/images/flags/us.svg', lang: 'en' },
    { text: 'Tamil', flag: 'assets/images/flags/spain.svg', lang: 'tn' },
    { text: 'Kannada', flag: 'assets/images/flags/germany.svg', lang: 'kn' },
  ];
  themes: Array<any> = [
    { name: 'Dark', iconName: 'dark_mode', value: 'dark' },
    { name: 'Light', iconName: 'light_mode', value: 'light' },
  ];
  colors: Array<any> = [
    { name: 'White', color: 'white' },
    { name: 'Black', color: 'black' },
    { name: 'Purple', color: 'purple' },
    { name: 'Orange', color: 'orange' },
    { name: 'Cyan', color: 'cyan' },
    { name: 'Green', color: 'green' },
    { name: 'Blue', color: 'blue' },
  ];
  notifications: any[] = [];
  notificationCount = 0;
  isDarkTheme = false;
  isFullScreen = false;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private configService: ConfigService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private router: Router,
    public languageService: LanguageService,
    public apiService: ApiService,
    private common: CommonService,
    private directionService: DirectionService
  ) {
    super();
    this.config = this.configService.configData;
    const themeName: string = localStorage.getItem('theme');
    const isDarkTheme: boolean = themeName && themeName === 'dark' ? true : false;
    this.setTheme(isDarkTheme);    
  }

  ngOnInit(): void {
    this.updateSchoolData();
    this.userType = this.authService.currentUserValue.role;
    const userRole = this.authService.currentUserValue.role;
    this.userImg = this.authService.currentUserValue.image;
    this.userName = this.authService.currentUserValue.name;
    this.department_name = this.authService.currentUserValue.department_name;
    this.entityType = this.authService.currentUserValue.entitytype;
    if (userRole === Role.Admin) {
      this.homePage = 'admin/dashboard/main';
    } else if (userRole === Role.Teacher) {
      this.homePage = 'teacher/dashboard';
    } else if (userRole === Role.Student) {
      this.homePage = 'student/dashboard';
      this.userName = this.authService.currentUserValue.first_name;
    } else {
      this.homePage = 'admin/dashboard/main';
    }

    this.langStoreValue = localStorage.getItem('lang');
    const val = this.listLang.filter((x) => x.lang === this.langStoreValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.defaultFlag = 'assets/images/flags/us.svg';
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }
    this.academicYearHandler();
    this.notificationService.getAllNotifications().subscribe((data: any) => {
      this.notifications = data;
      this.notificationCount = data.length;
    });
    
    this.entityType = this.authService.currentUserValue.entitytype;
  }

  datemethod(utcDate: any) {
    const dateValue = moment.utc(utcDate).local().format('ddd, DD MMM YYYY HH:mm:ss');
    return dateValue;
  }

  academicYearHandler(): void {
    this.common.getAcademicYear.subscribe((result: any) => {
      this.currentAcademic = result.currentYear;
      this.selectedAcademic = result.selectedYear;
      if(this.userType === 'Student' && this.entityType === 'College' && this.selectedAcademic){
        this.getClass();
      }
    });
    this.common.getAcademicYearList.subscribe((result: any) => {
      this.academic_years = result;
    });
  }

  onAcademicYearChange(args: any): void {
    if (args.isInteracted) {
      this.authService.currentUserSubject.value.academic_year = args.value
      this.common.setAcademicYear({ selectedYear: args.value, currentYear: this.currentAcademic });
      this.common.getLoginUserDetails();
    }
  }

  onClassChange(args: any): void {
    this.selectedClass = args.itemData?.class_id;
    if (args.isInteracted) {
      this.authService.currentUserSubject.value.class_id = args.value
      this.common.setClass({ class_id: args.itemData.class_id, class_name: args.itemData.class_name});
      this.common.getLoginUserDetails();
    }
  }

  getClass() {
    const url = `api/classinfo/allclasses/${this.authService.currentUserValue.school_id}/${this.selectedAcademic}/${this.authService.currentUserValue.id}`;
    this.apiService.get(url).subscribe((data: any) => {
        this.classes = data;
        if (data && data.length > 0) {
            this.selectedClass = data[0].class_id;
            const args = { isInteracted: true, value: this.selectedClass, itemData: data[0] };
            this.onClassChange(args);
        }
    });
}

  ngAfterViewInit(): void {
    if (localStorage.getItem('choose_skin')) {
      this.renderer.addClass(this.document.body, localStorage.getItem('choose_skin'));
    } else {
      this.renderer.addClass(this.document.body, 'theme-' + this.config.layout.theme_color);
    }

    if (localStorage.getItem('menuOption')) {
      if (localStorage.getItem('menuOption') === 'menu_dark') {
        this.setSidebarTheme(true);
      } else if (localStorage.getItem('menuOption') === 'menu_light') {
        this.setSidebarTheme(false);
      } else {
        this.setSidebarTheme(this.config.layout.sidebar.backgroundColor === 'dark');
      }
    } else {
      this.setSidebarTheme(this.config.layout.sidebar.backgroundColor === 'dark');
    }
    if (localStorage.getItem('theme')) {
      this.renderer.removeClass(this.document.body, this.config.layout.variant);
      this.renderer.addClass(this.document.body, localStorage.getItem('theme'));
    } else {
      this.renderer.addClass(this.document.body, this.config.layout.variant);
    }

    if (localStorage.getItem('menuOption')) {
      this.renderer.addClass(this.document.body, localStorage.getItem('menuOption'));
    } else {
      this.renderer.addClass(this.document.body, 'menu_' + this.config.layout.sidebar.backgroundColor);
    }

    if (localStorage.getItem('choose_logoheader')) {
      this.renderer.addClass(this.document.body, localStorage.getItem('choose_logoheader'));
    } else {
      this.renderer.addClass(this.document.body, 'logo-' + this.config.layout.logo_bg_color);
    }

    if (localStorage.getItem('sidebar_status')) {
      if (localStorage.getItem('sidebar_status') === 'close') {
        this.renderer.addClass(this.document.body, 'side-closed');
        this.renderer.addClass(this.document.body, 'submenu-closed');
      } else {
        this.renderer.removeClass(this.document.body, 'side-closed');
        this.renderer.removeClass(this.document.body, 'submenu-closed');
      }
    } else {
      if (this.config.layout.sidebar.collapsed === true) {
        this.renderer.addClass(this.document.body, 'side-closed');
        this.renderer.addClass(this.document.body, 'submenu-closed');
      }
    }
    this.getEntityThemeColour();
  }

  updateSchoolData(): void {
    this.apiService.get(`api/schoolinfo/schools/${this.authService.currentUserValue.school_id}`).subscribe({
      next: (data: any) => {
        if (data != null) {
          this.logoImage = data[0]['logo'];
          this.schoolName = data[0]['name'];
          this.authService.currentUserValue.entitytype = data[0]['entity_type'];
          console.log(this.authService.currentUserValue.entitytype)
        }
      },
      error: () => {
        this.apiService.showNotification('snackbar-danger', 'Error!!!');
      }
    });
  }

  callFullscreen(): void {
    if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
      this.isFullScreen = true;
      document.documentElement.requestFullscreen && document.documentElement.requestFullscreen();
      document.documentElement.msRequestFullscreen && document.documentElement.msRequestFullscreen();
      document.documentElement.mozRequestFullScreen && document.documentElement.mozRequestFullScreen();
      document.documentElement.webkitRequestFullscreen && document.documentElement.webkitRequestFullscreen();
    } else if (document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement) {
      this.isFullScreen = false;
      document.exitFullscreen && document.exitFullscreen();
      document.msExitFullscreen && document.msExitFullscreen();
      document.mozCancelFullScreen && document.mozCancelFullScreen();
      document.webkitExitFullscreen && document.webkitExitFullscreen();
    }
  }

  setLanguage(text: string, lang: string, flag: string): void {
    this.countryName = text;
    this.flagvalue = flag;
    this.langStoreValue = lang;
    this.languageService.setLanguage(lang);
  }

  mobileMenuSidebarOpen(event: any, className: string): void {
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }

  callSideMenuCollapse(): void {
    const hasClass = this.document.body.classList.contains('side-closed');
    if (hasClass) {
      this.renderer.removeClass(this.document.body, 'side-closed');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    } else {
      this.renderer.addClass(this.document.body, 'side-closed');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }

  logout(): void {
    this.subs.sink = this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(['/authentication/signin']);
      }
    });
    this.authService.clearLocalStorageItems();
  }

  profileInfo(): void {
    const userRole = this.authService.currentUserValue.role;
    let navUrl = '';
    if (userRole === Role.Admin) {
      navUrl = `admin/staff/about-staff/${this.authService.currentUserValue.id}`;
    } else if (userRole === Role.Teacher) {
      navUrl = `teacher/about/about-teacher/${this.authService.currentUserValue.id}`;
    } else if (userRole === Role.Student) {
      navUrl = `student/about/about-students/${this.authService.currentUserValue.id}`;
    }
    navUrl && this.router.navigate([navUrl], { queryParams: { accountPage: true } });
  }

  settings(): void {
    this.selectedIndex = 1;
    const userRole = this.authService.currentUserValue.role;
    let navUrl = '';
    if (userRole === Role.Admin) {
      navUrl = `admin/staff/about-staff/${this.authService.currentUserValue.id}`;
    } else if (userRole === Role.Teacher) {
      navUrl = `teacher/about/about-teacher/${this.authService.currentUserValue.id}`;
    } else if (userRole === Role.Student) {
      navUrl = `student/about/about-students/${this.authService.currentUserValue.id}`;
    }
    navUrl &&
      this.router.navigate([navUrl], { queryParams: { selectedTabIndex: 1 } });
  }

  school(): void {
    this.router.navigate(['admin/school/school-info']);
  }

  updateBoardingLocation(): void {
    this.router.navigate(['/student/transport/boardingpoint']);
  }

  getNotification(): void {
    this.notificationService.getAllNotifications().subscribe((data: any) => {
      this.notifications = data;
      this.notificationCount = data.length;
    });
  }

  markAllAsRead(): void {
    const notificationIds = this.notifications.map((item: any) => item.id);
    const ids_data: string = notificationIds.join(',');
    this.notificationService.deleteNotification(ids_data).subscribe((data: any) => {
      this.getNotification();
    });
  }

  markAsRead(notification: any): void {
    const ids_data: string = notification.id;
    this.notificationService.deleteNotification(ids_data).subscribe((data: any) => {
      this.getNotification();
    });
  }

  getEntityThemeColour(){
    this.common.getEntityTheme().subscribe((result: any)=>{
    if (result) {
      this.setHeaderTheme(result[0].header);
      this.setSidebarTheme(result[0].sidebar === 'dark');
    }
    })
    
  }

  setEntityThemeColour(themeOrColor: any, isSidebar: boolean): void {
    const userRole = this.authService.currentUserValue.role;

    if (userRole === Role.Admin) {      
      let header = null;
      let sidebar = null;
      isSidebar ? sidebar = themeOrColor.value : header = themeOrColor.color; 
      this.common.setEntityTheme(header, sidebar);      
    }
    if (isSidebar) {
      this.setSidebarTheme(themeOrColor.value === 'dark');
    } else {
      this.setHeaderTheme(themeOrColor.color);
    }
  }

  setSidebarTheme(isDarkTheme: boolean): void {
    this.renderer.removeClass(this.document.body, isDarkTheme ? 'menu_light' : 'menu_dark');
    this.renderer.removeClass(this.document.body, isDarkTheme ? 'logo-white' : 'logo-black');
    this.renderer.addClass(this.document.body, isDarkTheme ? 'menu_dark' : 'menu_light');
    this.renderer.addClass(this.document.body, isDarkTheme ? 'logo-black' : 'logo-white');
    localStorage.setItem('choose_logoheader', isDarkTheme ? 'logo-black' : 'logo-white');
    localStorage.setItem('menuOption', isDarkTheme ? 'menu_dark' : 'menu_light');
  }

  setHeaderTheme(color: string): void {
    const selectedBgColor = color;
    const prevTheme = localStorage.getItem('choose_skin');
    this.renderer.removeClass(this.document.body, prevTheme);
    this.renderer.addClass(this.document.body, 'theme-' + selectedBgColor);
    localStorage.setItem('choose_skin', 'theme-' + selectedBgColor);
    localStorage.setItem('choose_skin_active', selectedBgColor);
  }

  setTheme(isDarkTheme: boolean): void {
    this.isDarkTheme = isDarkTheme;
    const linkEle = this.document.head.querySelector('link#themeCss');
    linkEle.setAttribute('href', `https://cdn.syncfusion.com/ej2/21.1.35/tailwind${this.isDarkTheme ? '-dark' : ''}.css`);
    this.renderer.removeClass(this.document.body, this.isDarkTheme ? 'light' : 'dark');
    this.renderer.removeClass(this.document.body, 'submenu-closed');
    this.renderer.removeClass(this.document.body, `menu_${this.isDarkTheme ? 'light' : 'dark'}`);
    this.renderer.removeClass(this.document.body, `logo-${this.isDarkTheme ? 'white' : 'black'}`);
    this.renderer.removeClass(this.document.body, localStorage.getItem('choose_skin') || `theme-${this.config.layout.theme_color}`);
    this.renderer.addClass(this.document.body, this.isDarkTheme ? 'dark' : 'light');
    this.renderer.addClass(this.document.body, 'submenu-closed');
    this.renderer.addClass(this.document.body, this.isDarkTheme ? 'menu_dark' : 'menu_light');
    this.renderer.addClass(this.document.body, this.isDarkTheme ? 'logo-black' : 'logo-white');
    this.renderer.addClass(this.document.body, this.isDarkTheme ? 'theme-black' : 'theme-white');
    localStorage.setItem('choose_logoheader', this.isDarkTheme ? 'logo-black' : 'logo-white');
    localStorage.setItem('choose_skin', this.isDarkTheme ? 'theme-black' : 'theme-white');
    localStorage.setItem('theme', this.isDarkTheme ? 'dark' : 'light');
    localStorage.setItem('menuOption', this.isDarkTheme ? 'menu_dark' : 'menu_light');
  }

  switchDirection(): void {
    if (this.document.documentElement.hasAttribute('dir')) {
      this.document.documentElement.removeAttribute('dir');
      this.renderer.removeClass(this.document.body, 'rtl');
      this.directionService.updateDirection('ltr');
    } else {
      this.document.documentElement.setAttribute('dir', 'rtl');
      this.renderer.addClass(this.document.body, 'rtl');
      this.directionService.updateDirection('rtl');
    }
  }

  mouseOut(args: any) {
    if (args.toElement)
      this.isNavbarCollapsed = !args.toElement.closest('.header-submenu');
  }
}
