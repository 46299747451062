import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-forceupdate',
  templateUrl: './forceupdate.component.html'
})
export class ForceupdateComponent {
  appUrl: any;
  constructor(public dialogRef: MatDialogRef<ForceupdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public platform: Platform) {
    if (this.platform.is('android')) {
      this.appUrl = "https://play.google.com/store/apps/details?id=com.klartdigi.schoolerp"
    } else if (this.platform.is('ios')) {
      this.appUrl = "https://apps.apple.com/in/app/klartopedia/id6468777314"
    }
  }

}
