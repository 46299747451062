import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authService.currentUserValue) {
      this.router.navigate(['/authentication/signin']);
      return false;
    }

    if (this.authService.currentUserValue && (state.url === '' || state.url === '/')) {
      this.userProfile();
      return false;
    }

    if (this.authService.currentUserValue.role === 'Admin' && this.authService.currentUserValue.department_name !== 'super admin') {
      const dynamicId = state.url.replace(/[^0-9]/g, "");
      if (this.authService.urlComponentMapping[state.url] || (dynamicId && this.authService.urlComponentMapping[state.url.split('/' + dynamicId)[0]])) {
        const url = this.authService.urlComponentMapping[state.url] ? state.url : state.url.split('/' + dynamicId)[0];
        const write_access = JSON.parse(this.authService.currentUserValue.write_access || null);
        const read_access = JSON.parse(this.authService.currentUserValue.read_access || null);
        return write_access?.indexOf(this.authService.urlComponentMapping[url]) > -1 || read_access?.indexOf(this.authService.urlComponentMapping[url]) > -1;
      }
    }

    if (this.authService.currentUserValue) {
      const userRole = this.authService.currentUserValue.role;
      if (route?.data?.role?.indexOf(userRole) === -1) {
        this.router.navigate(['/authentication/signin']);
        return false;
      }
      return true;
    }

    this.router.navigate(['/authentication/signin']);
    return false;
  }

  userProfile() {
    const profile = this.authService.currentUserValue;
    if (profile.role == 'Admin') {
      const read_access = JSON.parse(this.authService.currentUserValue.read_access || null);
      const initialPage = read_access?.length
        ? Object.keys(this.authService.urlComponentMapping).find((key) => read_access.indexOf(this.authService.urlComponentMapping[key]) > -1)
        : '/admin/dashboard/main';
      this.router.navigate([initialPage]);
    } else if (profile.role == 'Teacher') {
      this.router.navigate(['/teacher/dashboard']);
    } else if (profile.role == 'Student') {
      this.router.navigate(['/student/dashboard']);
    }
    this.authService.currentUserSubject.next(profile);
  }
}
