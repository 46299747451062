import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  localeValue = new BehaviorSubject({ lang: 'en' });
  public languages: string[] = ['en', 'es', 'de', 'tn', 'kn'];

  constructor(public translate: TranslateService, private authService: AuthService) {
    let browserLang;
    translate.addLangs(this.languages);
    const role = this.authService?.currentUserValue?.role;
    if (role !== 'Student') {
      localStorage.removeItem('lang');
    }
    if (localStorage.getItem('lang')) {
      browserLang = localStorage.getItem('lang');
    } else {
      browserLang = translate.getBrowserLang();
    }
    translate.use(browserLang.match(/en|es|de|tn|kn/) ? browserLang : 'en');
  }

  get syncfusionLocale(): any {
    return this.localeValue.asObservable();
  }

  public setLanguage(lang: string) {
    this.translate.use(lang);
    localStorage.setItem('lang', lang);
    const val = { lang: lang };
    this.localeValue.next(val);
  }
}
