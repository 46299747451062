import { RouteInfo } from './sidebar.metadata';
export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'MENUITEMS.MAIN.TEXT',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
    component: 'Main',
  },

  // Admin Modules

  {
    path: '/admin/dashboard/main',
    title: 'MENUITEMS.DASHBOARD.TEXT',
    iconType: 'material-icons',
    icon: 'space_dashboard',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    component: 'Admin DashBoard',
    submenu: [],
  },
  {
    path: '/admin/action-pending',
    title: 'MENUITEMS.ACTIONPENDING.LIST.ACTIONPENDING',
    iconType: 'material-icons',
    icon: 'pending_actions',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    component: 'Action Pending',
    submenu: [],
  },
  {
    path: '',
    title: 'MENUITEMS.DEPARTMENTS.TEXT',
    iconType: 'material-icons',
    icon: 'business',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    component: 'Departments',
    submenu: [
      {
        path: '/admin/departments/all-departments',
        title: 'MENUITEMS.DEPARTMENTS.LIST.ALL-DEPARTMENT',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'All Departments',
      },
      {
        path: '/admin/departments/add-department',
        title: 'MENUITEMS.DEPARTMENTS.LIST.ADD-DEPARTMENT',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'Add Department',
      },
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.HOUSE.TEXT',
    iconType: 'material-icons',
    icon: 'house',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    component: 'Houses',
    submenu: [
      {
        path: '/admin/houses/all-houses',
        title: 'MENUITEMS.HOUSE.LIST.ALL-HOUSE',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'All Houses',
      },
      {
        path: '/admin/houses/add-house',
        title: 'MENUITEMS.HOUSE.LIST.ADD-HOUSE',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'Add House',
      },
    ],
  },
  {
    path: '/admin/subject/all-subject',
    title: 'MENUITEMS.SUBJECT.TEXT',
    iconType: 'material-icons',
    icon: 'subject',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    component: 'Subject',
    submenu: [],
  },

  {
    path: '',
    title: 'MENUITEMS.CLASS.TEXT',
    iconType: 'material-icons',
    icon: 'class',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    component: 'Class',
    submenu: [
      {
        path: '/admin/class/all-class',
        title: 'MENUITEMS.CLASS.LIST.ALL-CLASS',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'All Class',
      },
      {
        path: '/admin/class/add-class',
        title: 'MENUITEMS.CLASS.LIST.ADD-CLASS',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'Add Class',
      },
      {
        path: '/admin/subject-class-teacher-mapping/subject-class-teacher-mapping',
        title: 'MENUITEMS.SubjectClassTeacher.LIST.SubjectClassTeacher',
        iconType: 'material-icons',
        icon: 'subtitles',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Admin'],
        submenu: [],
        component: 'Subject Class Teacher',
      },
      {
        path: '/admin/period/all-period',
        title: 'MENUITEMS.CLASS.LIST.PERIOD',
        iconType: 'material-icons',
        icon: 'subtitles',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Admin'],
        submenu: [],
        component: 'Period',
      },
      {
        path: '/admin/timetable/scheduler',
        title: 'MENUITEMS.CLASS.LIST.TIMETABLE',
        iconType: 'material-icons',
        icon: 'event',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Admin'],
        submenu: [],
        component: 'Scheduler',
      }
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.TEACHERS.TEXT',
    iconType: 'material-icons',
    icon: 'person',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    component: 'Teachers',
    submenu: [
      {
        path: '/admin/teachers/all-teachers',
        title: 'MENUITEMS.TEACHERS.LIST.ALL-TEACHERS',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'All Teachers',
      },
      {
        path: '/admin/teachers/add-teacher',
        title: 'MENUITEMS.TEACHERS.LIST.ADD-TEACHER',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'Add Teacher',
      },
      {
        path: '/admin/teachers/substitute-teacher',
        title: 'MENUITEMS.TEACHERS.LIST.SUBSTITUTE-TEACHERS',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'Substitute Teacher',
      },
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.STUDENTS.TEXT',
    iconType: 'material-icons',
    icon: 'people_alt',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    component: 'Students',
    submenu: [
      {
        path: '/admin/students/all-students',
        title: 'MENUITEMS.STUDENTS.LIST.ALL-STUDENTS',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'All Students',
      },
      {
        path: '/admin/students/add-student',
        title: 'MENUITEMS.STUDENTS.LIST.ADD-STUDENT',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'Add Student',
      },
      {
        path: '/admin/students/analysis',
        title: 'MENUITEMS.STUDENTS.LIST.STUDENT-ANALYSIS',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Admin'],
        submenu: [],
        component: "Student Analysis"
      },
      {
        path: '/admin/students/consolidated',
        title: 'MENUITEMS.STUDENTS.LIST.STUDENT-CONSOLIDATED',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Admin'],
        submenu: [],
        component: "Student Consolidated"
      },
      {
        path: '/admin/students/student-promotion',
        title: 'MENUITEMS.STUDENTS.LIST.STUDENT-PROMOTION',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Admin'],
        submenu: [],
        component: "Student Promotion"
      },
      {
        path: '/admin/rating/student-rating',
        title: 'MENUITEMS.STUDENTS.RATINGS.MONTHLY-RATINGS',
        iconType: 'material-icons',
        icon: 'event_note',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Admin'],
        submenu: [],
        component: 'Rating',
      },
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.STUDENTS.CONFIGURATION.TEXT',
    iconType: 'material-icons',
    icon: 'event_note',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    component: 'configuration',
    submenu: [
      {
        path: '/admin/configuration/internal-marks',
        title: 'MENUITEMS.STUDENTS.CONFIGURATION.INTERNAL-MARKS',
        iconType: 'material-icons',
        icon: 'event_note',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Admin'],
        submenu: [],
        component: 'Internal Marks',
      },
      {
        path: '/admin/configuration/rating-params',
        title: 'MENUITEMS.STUDENTS.CONFIGURATION.RATING-PARAMS',
        iconType: 'material-icons',
        icon: 'event_note',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Admin'],
        submenu: [],
        component: 'Rating params',
      },
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.STAFF.TEXT',
    iconType: 'material-icons',
    icon: 'face',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    component: 'Staffs',
    submenu: [
      {
        path: '/admin/staff/all-staff',
        title: 'MENUITEMS.STAFF.LIST.ALL-STAFF',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'All Staff',
      },
      {
        path: '/admin/staff/add-staff',
        title: 'MENUITEMS.STAFF.LIST.ADD-STAFF',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'Add Staff',
      },
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.ATTENDANCE.TEXT',
    iconType: 'material-icons',
    icon: 'list_alt',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    component: 'Attendance',
    submenu: [
      {
        path: '/admin/students/student-attendance',
        title: 'MENUITEMS.STUDENTS.LIST.STUDENT-ATTENDANCE',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'Student Attendance',
      },
      {
        path: '/admin/attendance/teacher',
        title: 'MENUITEMS.ATTENDANCE.LIST.TEACHER',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Admin'],
        submenu: [],
        component: 'Teacher Attendance',
      },
      {
        path: '/admin/attendance/staff',
        title: 'MENUITEMS.ATTENDANCE.LIST.STAFF',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Admin'],
        submenu: [],
        component: 'Staff Attendance',
      },
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.EXAM.TEXT',
    iconType: 'material-icons',
    icon: 'menu_book',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    component: 'Exam',
    submenu: [
      {
        path: '/admin/exam/all-exams',
        title: 'MENUITEMS.EXAM.LIST.ALL-EXAM',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'All Exams',
      },
      {
        path: '/admin/exam-schedule/exam-schedule',
        title: 'MENUITEMS.TEACHER.LIST.EXAM-SCHEDULE',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Admin'],
        submenu: [],
        component: 'Exam Scheduler',
      },
      {
        path: '/admin/examresult/all-examresult',
        title: 'MENUITEMS.EXAM.LIST.EXAM-RESULT',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'Exam Result',
      },
      {
        path: '/admin/rating/exam-rating',
        title: 'MENUITEMS.STUDENTS.RATINGS.EXAM-RATINGS',
        iconType: 'material-icons',
        icon: 'event_note',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Admin'],
        submenu: [],
        component: 'Exam Rating',
      },
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.FEES.TEXT',
    iconType: 'material-icons',
    icon: 'monetization_on',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    component: 'Fees',
    submenu: [
      {
        path: '/admin/fees/fee-structure',
        title: 'MENUITEMS.FEES.LIST.ALL-FEES',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'Fee Strucutre',
      },
      {
        path: '/admin/fees/fee-allocation',
        title: 'MENUITEMS.FEES.LIST.FEE-ALLOCATION',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'Fee Allocation',
      },
      {
        path: '/admin/fees/fee-entry',
        title: 'MENUITEMS.FEES.LIST.ADD-FEES',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'Student Fee Entry',
      },
      {
        path: '/admin/fees/student-fees-detail',
        title: 'MENUITEMS.FEES.LIST.STUDENT-FEE-DETAILS',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'Student Fee Details',
      },
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.COURSES.TEXT',
    iconType: 'material-icons',
    icon: 'school',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    component: 'Courses',
    submenu: [
      {
        path: '/admin/courses/all-courses',
        title: 'MENUITEMS.COURSES.LIST.ALL-COURSES',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'All Courses',
      },
      {
        path: '/admin/courses/add-course',
        title: 'MENUITEMS.COURSES.LIST.ADD-COURSE',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'Add Course',
      },
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.LIBRARY.TEXT',
    iconType: 'material-icons',
    icon: 'local_library',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    component: 'Library',
    submenu: [
      {
        path: '/admin/library/all-assets',
        title: 'MENUITEMS.LIBRARY.LIST.ALL-LIBRARY',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'All Library Assests',
      },
      {
        path: '/admin/library/add-asset',
        title: 'MENUITEMS.LIBRARY.LIST.ADD-LIBRARY',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'Add Library Asset',
      },
      {
        path: '/admin/library/add-student-asset',
        title: 'MENUITEMS.LIBRARY.LIST.STUDENT-ASSET',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'Library Assest Entry',
      },
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.TRANSPORT.TEXT',
    iconType: 'material-icons',
    icon: 'directions_bus',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    component: 'Transport',
    submenu: [
      {
        path: '/admin/transport/stop',
        title: 'MENUITEMS.TRANSPORT.LIST.STOP',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'Stop',
      },
      {
        path: '/admin/transport/route',
        title: 'MENUITEMS.TRANSPORT.LIST.ROUTE',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'Route',
      },
      {
        path: '/admin/transport/bus',
        title: 'MENUITEMS.TRANSPORT.LIST.BUS',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'Bus',
      },
      {
        path: '/admin/transport/driver-allocation',
        title: 'MENUITEMS.TRANSPORT.LIST.DRIVER-ALLOCATION',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'Driver Allocation',
      },
      {
        path: '/admin/transport/daily-bus-tracker',
        title: 'MENUITEMS.TRANSPORT.LIST.LIVE-BUS-TRACKER',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'Daily Bus Tracker',
      },
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.HOLIDAY.TEXT',
    iconType: 'material-icons',
    icon: 'airline_seat_individual_suite',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    component: 'Holiday',
    submenu: [
      {
        path: '/admin/holidays/all-holidays',
        title: 'MENUITEMS.HOLIDAY.LIST.ALL-HOLIDAY',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'All Holiday',
      },
      {
        path: '/admin/holidays/add-holiday',
        title: 'MENUITEMS.HOLIDAY.LIST.ADD-HOLIDAY',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'Add Holiday',
      },
    ],
  },
  {
    path: '/admin/leave/all-leaves',
    title: 'MENUITEMS.LEAVE.APPLY-LEAVE',
    iconType: 'material-icons',
    icon: 'note_add',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: [],
    submenu: [],
    component: 'Apply Leave',
  },
  {
    path: '',
    title: 'MENUITEMS.LEAVE.TEXT',
    iconType: 'material-icons',
    icon: 'help',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    component: 'Leave Request',
    submenu: [
      {
        path: '/admin/leave-request/student',
        title: 'MENUITEMS.LEAVE.LIST.LEAVE-STUDENT',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'Student',
      },
      {
        path: '/admin/leave-request/staff',
        title: 'MENUITEMS.LEAVE.LIST.STAFF',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'Staff',
      },
      {
        path: '/admin/leave-request/teacher',
        title: 'MENUITEMS.LEAVE.LIST.TEACHER',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'Teacher',
      },
      {
        path: '/admin/leave-request/admin',
        title: 'MENUITEMS.LEAVE.LIST.LEAVE-ADMIN',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'Admin',
      },
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.EVENTS.TEXT',
    iconType: 'material-icons',
    icon: 'event',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    component: 'Events',
    submenu: [
      {
        path: '/admin/events/all-events',
        title: 'MENUITEMS.EVENTS.LIST.ALL-EVENTS',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'All Events',
      },
      {
        path: '/admin/events/add-events',
        title: 'MENUITEMS.EVENTS.LIST.ADD-EVENTS',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'Add Events',
      },
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.NOTICEBOARD.TEXT',
    iconType: 'material-icons',
    icon: 'event_note',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    component: 'Noticeboard',
    submenu: [
      {
        path: '/admin/noticeboard/all-noticeboard',
        title: 'MENUITEMS.NOTICEBOARD.LIST.ALL-NOTICEBOARD',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'All Noticeboards',
      },
      {
        path: '/admin/noticeboard/add-noticeboard',
        title: 'MENUITEMS.NOTICEBOARD.LIST.ADD-NOTICEBOARD',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
        component: 'Add Noticeboard',
      },
    ],
  },
  {
    path: '/admin/special-day/greets',
    title: 'MENUITEMS.SPECIALDAYGREETS.TEXT',
    iconType: 'material-icons',
    icon: 'calendar_today',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    component: 'Special Day Greets',
    submenu: [],
  },
  {
    path: '/admin/accessmanagement/all-accessmanagement',
    title: 'MENUITEMS.ACCESSMANAGEMENT.LIST.ACCESSMANAGEMENT',
    iconType: 'material-icons',
    icon: 'subtitles',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [],
    component: 'Access Management',
  },
  {
    path: '/admin/broadcast/all-broadcast',
    title: 'MENUITEMS.BROADCAST.TEXT',
    iconType: 'material-icons',
    icon: 'campaign',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [],
    component: 'Broadcast',
  },
  {
    path: '/admin/gallery/all-gallery',
    title: 'MENUITEMS.GALLERY.TEXT',
    iconType: 'material-icons',
    icon: 'perm_media',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [],
    component: 'Gallery',
  },
  {
    path: '/admin/inventory/all-inventory',
    title: 'MENUITEMS.INVENTORY.TEXT',
    iconType: 'material-icons',
    icon: 'inventory',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    component: 'Inventory',
    submenu: [],
  },
  {
    path: 'chat',
    title: 'MENUITEMS.ASKME.TEXT',
    iconType: 'material-icons',
    icon: 'chat',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [],
    component: 'Ask Me',
  },

  // Teacher Modules
  {
    path: '/teacher/dashboard',
    title: 'MENUITEMS.TEACHER.LIST.DASHBOARD',
    iconType: 'material-icons',
    icon: 'space_dashboard',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Teacher'],
    submenu: [],
  },

  {
    path: '/teacher/lectures/scheduler',
    title: 'MENUITEMS.TEACHER.LIST.LECTURES',
    iconType: 'material-icons',
    icon: 'event',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Teacher'],
    submenu: [],
  },
  {
    path: '/teacher/time-table/scheduler',
    title: 'MENUITEMS.TEACHER.LIST.TIMETABLE',
    iconType: 'material-icons',
    icon: 'table_chart',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Teacher'],
    submenu: [],
  },
  {
    path: '',
    title: 'MENUITEMS.STUDENTS.TEXT',
    iconType: 'material-icons',
    icon: 'people_alt',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Teacher'],
    submenu: [
      {
        path: '/teacher/students/all-students',
        title: 'MENUITEMS.STUDENTS.LIST.ALL-STUDENTS',
        iconType: 'material-icons',
        icon: 'people_alt',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Teacher'],
        submenu: []
      },
      {
        path: '/teacher/students/add-student',
        title: 'MENUITEMS.STUDENTS.LIST.ADD-STUDENT',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/teacher/students/analysis',
        title: 'MENUITEMS.STUDENTS.LIST.STUDENT-ANALYSIS',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Teacher'],
        submenu: []
      },
      {
        path: '/teacher/students/consolidated',
        title: 'MENUITEMS.STUDENTS.LIST.STUDENT-CONSOLIDATED',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Teacher'],
        submenu: []
      },
      {
        path: '/teacher/students/student-promotion',
        title: 'MENUITEMS.STUDENTS.LIST.STUDENT-PROMOTION',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Teacher'],
        submenu: []
      },
      {
        path: '/teacher/rating/student-rating',
        title: 'MENUITEMS.STUDENTS.RATINGS.MONTHLY-RATINGS',
        iconType: 'material-icons',
        icon: 'event_note',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Teacher'],
        submenu: [],
      }
    ],
    component: 'student',
  },
  {
    path: '',
    title: 'MENUITEMS.ATTENDANCE.TEXT',
    iconType: 'material-icons',
    icon: 'list_alt',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Teacher'],
    submenu: [
      {
        path: '/teacher/student-attendance',
        title: 'MENUITEMS.STUDENTS.LIST.TEACHER-STUD-ATTENDANCE',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Teacher'],
        submenu: [],
      },
      {
        path: '/teacher/approve-leave',
        title: 'MENUITEMS.STUDENTS.LIST.TEACHER-APPROVE-LEAVE',
        iconType: 'material-icons',
        icon: 'offline_pin',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Teacher'],
        submenu: [],
      },
    ],
  },
  {
    path: '/teacher/homework/homework',
    title: 'MENUITEMS.TEACHER.LIST.HOMEWORK',
    iconType: 'material-icons',
    icon: 'article',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Teacher'],
    submenu: [],
  },
  {
    path: '/teacher/worklog',
    title: 'Worklog',
    iconType: 'material-icons',
    icon: 'article',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Teacher'],
    submenu: [],
  },
  {
    path: '',
    title: 'MENUITEMS.TEACHER.LIST.EXAM',
    iconType: 'material-icons',
    icon: 'menu_book',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Teacher'],
    component: 'Teacher Exam Result',
    submenu: [
      {
        path: '/teacher/exam/all-exams',
        title: 'MENUITEMS.TEACHER.LIST.ALL-EXAM',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/teacher/exam/add-exam',
        title: 'MENUITEMS.TEACHER.LIST.ADD-EXAM',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/teacher/exam-schedule/exam-schedule',
        title: 'MENUITEMS.TEACHER.LIST.EXAM-SCHEDULE',
        iconType: 'material-icons',
        icon: 'event_note',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Teacher'],
        submenu: [],
      },
      {
        path: '/teacher/examresult/all-examresult',
        title: 'MENUITEMS.TEACHER.LIST.ALL-EXAMRESULT',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/teacher/examresult/add-examresult',
        title: 'MENUITEMS.TEACHER.LIST.ADD-EXAMRESULT',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/teacher/rating/exam-rating',
        title: 'MENUITEMS.STUDENTS.RATINGS.EXAM-RATINGS',
        iconType: 'material-icons',
        icon: 'event_note',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Teacher'],
        submenu: [],
      },
    ],
  },
  {
    path: '/teacher/holidays/all-holidays',
    title: 'MENUITEMS.HOLIDAY.TEXT',
    iconType: 'material-icons',
    icon: 'airline_seat_individual_suite',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Teacher'],
    submenu: [],
  },
  {
    path: '',
    title: 'MENUITEMS.STUDENTS.RATINGS.TEXT',
    iconType: 'material-icons',
    icon: 'event_note',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Teacher'],
    submenu: [
      {
        path: '/teacher/rating/student-rating',
        title: 'MENUITEMS.STUDENTS.RATINGS.MONTHLY-RATINGS',
        iconType: 'material-icons',
        icon: 'event_note',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Teacher'],
        submenu: [],
      },
    ],
  },
  {
    path: '/teacher/transport/daily-bus-tracker',
    title: 'MENUITEMS.TRANSPORT.LIST.LIVE-BUS-TRACKER',
    iconType: 'material-icons',
    icon: 'directions_bus',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Teacher'],
    submenu: [],
  },
  {
    path: '/teacher/leave/all-leaves',
    title: 'MENUITEMS.TEACHER.LIST.LEAVE-REQUEST',
    iconType: 'material-icons',
    icon: 'note_add',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Teacher'],
    submenu: [],
  },
  {
    path: '/teacher/courses/all-courses',
    title: 'MENUITEMS.COURSES.TEXT',
    iconType: 'material-icons',
    icon: 'school',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Teacher'],
    submenu: [],
  },
  {
    path: '/teacher/library/library-assets',
    title: 'MENUITEMS.LIBRARY.TEXT',
    iconType: 'material-icons',
    icon: 'school',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Teacher'],
    submenu: [],
  },
  {
    path: '/teacher/events/all-events',
    title: 'MENUITEMS.EVENTS.TEXT',
    iconType: 'material-icons',
    icon: 'event',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Teacher'],
    submenu: [],
  },
  {
    path: '/teacher/noticeboard/all-noticeboard',
    title: 'MENUITEMS.NOTICEBOARD.TEXT',
    iconType: 'material-icons',
    icon: 'event_note',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Teacher'],
    submenu: [],
  },
  {
    path: '/teacher/gallery/all-gallery',
    title: 'MENUITEMS.GALLERY.TEXT',
    iconType: 'material-icons',
    icon: 'perm_media',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Teacher'],
    submenu: [],
  },
  {
    path: 'chat',
    title: 'MENUITEMS.ASKME.TEXT',
    iconType: 'material-icons',
    icon: 'chat',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Teacher'],
    submenu: [],
  },

  //Student modules
  {
    path: '/student/dashboard',
    title: 'MENUITEMS.STUDENT.LIST.DASHBOARD',
    iconType: 'material-icons',
    icon: 'space_dashboard',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Student'],
    submenu: [],
  },
  {
    path: '/student/timetable/scheduler',
    title: 'MENUITEMS.STUDENT.LIST.TIME-TABLE',
    iconType: 'material-icons',
    icon: 'table_chart',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Student'],
    submenu: [],
  },
  {
    path: '/student/attendance',
    title: 'MENUITEMS.STUDENT.LIST.ATTENDANCE',
    iconType: 'material-icons',
    icon: 'list_alt',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Student'],
    submenu: [],
  },
  {
    path: '/student/analysis',
    title: 'MENUITEMS.STUDENTS.LIST.STUDENT-ANALYSIS',
    iconType: 'material-icons',
    icon: 'book',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Student'],
    submenu: [],
  },
  {
    path: '/student/homework/homework',
    title: 'MENUITEMS.STUDENT.LIST.HOMEWORK',
    iconType: 'material-icons',
    icon: 'article',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Student'],
    submenu: [],
  },
  {
    path: '',
    title: 'MENUITEMS.STUDENT.LIST.EXAM',
    iconType: 'material-icons',
    icon: 'menu_book',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Student'],
    component: 'Student Exam Result',
    submenu: [
      {
        path: '/student/exam/all-exams',
        title: 'MENUITEMS.STUDENT.LIST.ALL-EXAM',
        iconType: 'material-icons',
        icon: 'menu_book',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [],
        submenu: [],
      },
      {
        path: '/student/exam-schedule/exam-schedule',
        title: 'MENUITEMS.STUDENT.LIST.EXAM-SCHEDULE',
        iconType: 'material-icons',
        icon: 'event_note',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [],
        submenu: [],
      },
      {
        path: '/student/examresult/all-examresult',
        title: 'MENUITEMS.EXAM.LIST.EXAM-RESULT',
        iconType: 'material-icons',
        icon: 'event_note',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [],
        submenu: [],
      },
    ],
  },

  {
    path: '/student/student-fees-details',
    title: 'MENUITEMS.STUDENT.LIST.FEESDETAILS',
    iconType: 'material-icons',
    icon: 'monetization_on',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Student'],
    submenu: [],
  },

  {
    path: '/student/rating',
    title: 'MENUITEMS.EXAM.LIST.RATING',
    iconType: 'material-icons',
    icon: 'event_note',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Student'],
    submenu: [],
  },
  {
    path: '/student/transport/daily-bus-tracker',
    title: 'MENUITEMS.TRANSPORT.LIST.LIVE-BUS-TRACKER',
    iconType: 'material-icons',
    icon: 'directions_bus',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Student'],
    submenu: [],
  },
  {
    path: '/student/leave/all-leaves',
    title: 'MENUITEMS.STUDENT.LIST.LEAVE-REQUEST',
    iconType: 'material-icons',
    icon: 'note_add',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Student'],
    submenu: [],
  },
  {
    path: '/student/holidays/all-holidays',
    title: 'MENUITEMS.HOLIDAY.TEXT',
    iconType: 'material-icons',
    icon: 'airline_seat_individual_suite',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Student'],
    submenu: [],
  },
  {
    path: '/student/events/all-events',
    title: 'MENUITEMS.EVENTS.TEXT',
    iconType: 'material-icons',
    icon: 'event',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Student'],
    submenu: [],
  },
  {
    path: '/student/library/library-assets',
    title: 'MENUITEMS.LIBRARY.TEXT',
    iconType: 'material-icons',
    icon: 'school',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Student'],
    submenu: [],
  },
  {
    path: '/student/courses/all-courses',
    title: 'MENUITEMS.COURSES.TEXT',
    iconType: 'material-icons',
    icon: 'school',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Student'],
    submenu: [],
  },
  {
    path: '/student/noticeboard/all-noticeboard',
    title: 'MENUITEMS.NOTICEBOARD.TEXT',
    iconType: 'material-icons',
    icon: 'event_note',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Student'],
    submenu: [],
  },
  {
    path: '/student/gallery/all-gallery',
    title: 'MENUITEMS.GALLERY.TEXT',
    iconType: 'material-icons',
    icon: 'perm_media',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Student'],
    submenu: [],
  }
];
