<section>
  <h2 mat-dialog-title>Please Select Boarding Location</h2>
  <mat-dialog-content style="max-height: 100vh;">
    <google-map (mapClick)="eventHandler($event,'mapClick')" width="100%" [zoom]="zoom" [center]="center"
      [options]="options">
      <map-marker #marker="mapMarker" [position]="markers.position" [options]="markers.options"
        (positionChanged)="openInfoWindow(marker)">
      </map-marker>
      <map-info-window class="mapInfo">
        <p><b>Address:</b> {{address}}</p>
        <p><b>Coordinate:</b> <span style="color:#0d6efd;">
            {{markers?.position?.lat}},{{markers?.position?.lng}}</span></p>
      </map-info-window>
    </google-map>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button [disabled]="!markers?.position?.lat" mat-raised-button color="primary" type="submit"
      (click)="confirmAdd()">Confirm</button>
  </mat-dialog-actions>
</section>
