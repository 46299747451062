import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from './auth.service';

@Pipe({ name: 'teachertoprofessor' })
export class TeacherToProfessorPipe implements PipeTransform {
  constructor(public authService: AuthService) { }

  transform(data: any): string {
    if (this.authService.currentUserValue?.entitytype?.toLowerCase() === 'college') {
      return 'Faculty'
    } else {
      return data;
    }
  }
}
