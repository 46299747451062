import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'defaultimage' })
export class DefaultImagePipe implements PipeTransform {
  transform(image: any): string {
    if (image == '' || image == 'null' || image == null || image == undefined || image == false || image == 'false') {
      return "https://st.depositphotos.com/2218212/2938/i/600/depositphotos_29387653-stock-photo-facebook-profile.jpg"
    } else {
      return image;
    }
  }
}
