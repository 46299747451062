import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { Router } from '@angular/router';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { formatDate } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NoticeBoardService } from '../../services/noticeboard.service';
import { EventService } from '../../services/event.service';
import { CarouselAnimationEffect } from '@syncfusion/ej2-angular-navigations';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-carousel-popup',
  templateUrl: './carousel-popup.component.html',
  styleUrls: ['./carousel-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CarouselPopupComponent implements OnInit {
  noticeboardList: any;
  eventsList: any[];
  carouselList: any = [];
  @ViewChild('template') template: DialogComponent | any;
  public carouselAnimation: CarouselAnimationEffect = 'Fade';
  public targetElement?: any;
  currentDate = new Date();
  selectedAcademic: any;
  constructor(
    private apiService: ApiService,
    private router: Router,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private common: CommonService
  ) { }


  ngOnInit(): void {
    this.initilaizeTarget();
    this.common.academic_year.subscribe((result: any) => {
      if (result?.selectedYear) {
        this.selectedAcademic = result.selectedYear;
        this.noticeData();
      }
    });

  }

  loadData() {
    this.noticeData();
  }
  public initilaizeTarget: any = () => {
    this.targetElement = 'body';
  };
  public height = '250px';
  public dialogOpen: any = () => { };
  getCurrentDateItems(data: any, dateProps: any) {
    const currentDate = this.currentDate;
    let list = JSON.parse(localStorage.getItem('viewednoticeandevent')) || {};
    list = list[currentDate.getDate()];
    currentDate.setHours(0, 0, 0, 0);
    if (dateProps === 'date_time') {
      const result = [];
      for (let i = 0; i < data.length; i++) {
        const noticeDate = new Date(data[i].date_time);
        noticeDate.setHours(0, 0, 0, 0);
        if (
          !list?.notice?.includes(data[i].id) &&
          noticeDate.getDate() == currentDate.getDate() &&
          noticeDate.getMonth() == currentDate.getMonth() &&
          noticeDate.getFullYear() == currentDate.getFullYear()
        ) {
          if (
            this.authService.currentUserValue.role !== 'Student' || !data[i].class_id ||
            !JSON.parse(data[i].class_id).length || JSON.parse(data[i].class_id).includes(this.authService.currentUserValue.class_id)
          ) {
            result.push(data[i]);
          }
        }
      }
      return result;
    } else {
      const result = [];
      for (let i = 0; i < data.length; i++) {
        const startDate = new Date(data[i].start_date);
        const endDate = new Date(data[i].end_date);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);

        if (
          !list?.event?.includes(data[i].id) &&
          currentDate.getDate() >= startDate.getDate() &&
          startDate.getMonth() === currentDate.getMonth() &&
          startDate.getFullYear() === currentDate.getFullYear() &&
          currentDate.getDate() <= endDate.getDate() &&
          endDate.getMonth() === currentDate.getMonth() &&
          endDate.getFullYear() === currentDate.getFullYear()
        ) {
          if (
            this.authService.currentUserValue.role !== 'Student' ||
            !data[i].class_id ||
            !JSON.parse(data[i].class_id).length ||
            JSON.parse(data[i].class_id).includes(
              this.authService.currentUserValue.class_id
            )
          ) {
            data[i]['isEvent'] = true;
            result.push(data[i]);
          }
        }
      }
      return result;
    }
  }
  getAllEvents(): any {
    const params: any = {
      schoolid: this.authService.currentUserValue.school_id,
      academic_year: this.selectedAcademic
    }
    return this.apiService.get('api/eventinfo/allevent', { params: params });
  }

  eventData() {
    this.eventsList = [];
    this.getAllEvents().subscribe((data: any) => {
      if (data?.length) {
        this.eventsList = this.getCurrentDateItems(data, 'start_date');
      }
      if (this.eventsList?.length || this.noticeboardList?.length) {
        this.carouselList = this.eventsList?.concat(this.noticeboardList);
        const date = this.currentDate.getDate();
        const name = this.eventsList?.length ? 'event' : 'notice';
        let list: any = localStorage.getItem('viewednoticeandevent') || {};
        if (list) {
          list = typeof list === 'string' ? JSON.parse(list)[date] || {} : list;
          list[name]?.length ? list[name].push(this.carouselList[0].id) : list[name] = [this.carouselList[0].id];
          localStorage.setItem('viewednoticeandevent', JSON.stringify({ [date]: list }));
        }
        const interval = setInterval(() => {
          const carousel: any = document.querySelector(
            '.e-carousel .e-carousel-items:first-child'
          );
          if (carousel) {
            carousel.style.transform = 'translateX(0px)';
            clearInterval(interval);
          }
        }, 100);
      }
    });
  }
  getAllNoticeBoards(): any {
    const params: any = {
      schoolid: this.authService.currentUserValue.school_id,
      academic_year: this.selectedAcademic
    }
    return this.apiService.get('api/noticeboardinfo/allnoticeboards', { params: params });
  }
  noticeData() {
    this.noticeboardList = [];
    this.getAllNoticeBoards().subscribe((data: any) => {
      if (data?.length) {
        this.noticeboardList = this.getCurrentDateItems(data, 'date_time');
      }
      this.eventData();
    });
  }

  redirectToPage(item: any): void {
    const rolePath = this.authService.currentUserValue.role === 'Admin' ? 'admin' :
                     this.authService.currentUserValue.role === 'Teacher' ? 'teacher' : 'student';
    
    const path = item.isEvent ? `${rolePath}/events/all-events` : `${rolePath}/noticeboard/all-noticeboard`;
    
    this.router.navigate([path], { queryParams: { id: item.id } });
    this.template.hide();
  }
  
  onSlideChanged(args: any) {
    const date = this.currentDate.getDate();
    let list: any = localStorage.getItem('viewednoticeandevent') || {};
    if (list) {
      list = typeof list === 'string' ? JSON.parse(list)[date] : list;
      if (this.carouselList[args.currentIndex]?.isEvent) {
        if (list['event']?.length) {
          list['event'].push(this.carouselList[args.currentIndex].id);
        } else {
          list['event'] = [this.carouselList[args.currentIndex].id];
        }
      } else {
        if (list['notice']?.length) {
          list['notice'].push(this.carouselList[args.currentIndex].id);
        } else {
          list['notice'] = [this.carouselList[args.currentIndex].id];
        }
      }
      localStorage.setItem('viewednoticeandevent', JSON.stringify({ [date]: list }));
    }
    if (args.currentIndex === 1 || args.currentIndex === 0) {
      (
        document.querySelector(
          '.e-carousel .e-carousel-items:first-child'
        ) as any
      ).style.transform = 'translateX(0px)';
    }
  }
}


