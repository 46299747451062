<section class="content">
  <div class="content-block">
    <div class="block-header" *ngFor="let breadscrum of breadscrums">
      <!-- breadcrumb -->
      <app-breadcrumb
        title="{{ 'MENUITEMS.ASKME.TEXT' | translate }}"
        [items]="breadscrum.items"
        active_item="{{ 'MENUITEMS.ASKME.CHAT' | translate }}"
      >
      </app-breadcrumb>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <br />
        <div class="card">
          <div class="body">
            <div class="row">
              <div class="col-md-12">
                <div class="chat-wrapper" #chatMessages>
                  <ng-container *ngFor="let item of chatConversation">
                    <div
                      class="message"
                      [ngClass]="{
                        from: item.person === 'you',
                        to: item.person === 'bot'
                      }"
                    >
                    <button *ngIf="item.person === 'bot'" mat-stroked-button color="primary" class="copy-btn" (click)="onCopy($event)">
                      Copy
                      <mat-icon>content_copy</mat-icon>
                    </button>
                      {{ item.response }}
                    </div>
                  </ng-container>
                  <div id="loader">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/staging-schoolerp-image-upload.appspot.com/o/dots-loading.gif?alt=media&token=e8d945c7-f8ae-4328-b207-6675ee097c19"
                      *ngIf="showSpinner"
                      width="50px"
                      height="50px"
                      alt="loading"
                    />
                  </div>
                  <br />
                 
                </div>
              </div>
              <div class="row">
                <div class = "col-md-12">
                  <textarea (keyup.enter)="checkResponse()"
                  row="3"
                  cols="15"
                  class="message-text form-control"
                  [(ngModel)]="promptText"
                  placeholder="{{ chatConversation.length > 0 ? '' : 'MENUITEMS.ASKME.PLACEHOLDER' | translate }}"></textarea>
                <button class="send" (click)="checkResponse()">
                  <mat-icon>send</mat-icon>
                </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
