import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from 'src/app/api.service';
@Injectable()
export class RightSidebarService {
  private sidebarSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  sidebarState = this.sidebarSubject.asObservable();

  constructor(private apiService: ApiService) { }

  setRightSidebar = (value: boolean) => {
    this.sidebarSubject.next(value);
  };

  getAccessDetails(staff_id: number): any {
    const url = `api/accessmanagementinfo/accessmanagement/${staff_id}`;
    return this.apiService.get(url);
  }
}
