import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UnsubscribeOnDestroyAdapter } from 'src/app/shared/UnsubscribeOnDestroyAdapter';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends UnsubscribeOnDestroyAdapter {
  private readonly API_URL = environment.apiUrl;
  isTblLoading = true;
  // Temporarily stores data from dialogs
  dialogData: any;
  constructor(private apiService: ApiService, private authService: AuthService) {
    super();
  }
  /** CRUD METHODS */
  getAllNotifications(): any {
    const userType = this.authService.currentUserValue.role === 'Admin' ? 'Staff' : this.authService.currentUserValue.role;
    return this.apiService.get(`api/notificationInfo/notification/${userType}/${this.authService.currentUserValue.id}`);
  }

  deleteNotification(id: string): any {
    const userType = this.authService.currentUserValue.role === 'Admin' ? 'Staff' : this.authService.currentUserValue.role;
    return this.apiService.delete(`api/notificationinfo/notification/${userType}/${id}`);
  }
}
