<h1 *ngIf="platform.is('android')" mat-dialog-title style="text-align: center;font-size: 24px;">Update App</h1>
<h1 *ngIf="platform.is('ios')" mat-dialog-title style="text-align: center;font-size: 24px;">INFORMATION</h1>
<div mat-dialog-content>
  <p *ngIf="platform.is('android')" style="padding: 10px;">
    A new version of School ERP app is available now. Please upgrade your app to use the latest version of the
    application.
  </p>
  <div *ngIf="platform.is('ios')" style="padding: 10px;">
    <p style="text-align: center;">Dear Users,</p>
    <p style="text-align: center;"> While we are enhancing IOS Application for better user experience,
      We Encourage you
      to utilise our web
      apllication for uninterrupted access.</p>
    <p style="text-align: center;">For web application, pls click below button</p>
  </div>
</div>
<div mat-dialog-actions style="justify-content: center;">
  <a *ngIf="platform.is('android')" href="{{appUrl}}" style="margin-bottom: 15px;" mat-button mat-raised-button
    color="primary" type="submit" cdkFocusInitial>Download</a>
  <a *ngIf="platform.is('ios')" href="https://www.klartopedia.in/#/authentication/signin" style="margin-bottom: 15px;"
    mat-button mat-raised-button color="primary" type="submit" cdkFocusInitial>Website Url</a>
</div>
