import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
})
export class Page404Component {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private authService: AuthService, private router: Router) { }

  goToHomePage() {
    const userRole = this.authService.currentUserValue.role;
    if (userRole == 'Admin') {
      this.router.navigate(['/admin/dashboard/main']);
    } else if (userRole == 'Teacher') {
      this.router.navigate(['/teacher/dashboard']);
    } else if (userRole == 'Student') {
      this.router.navigate(['/student/dashboard']);
    } else {
      this.router.navigate(['/authentication/signin']);
    }
  }
}
