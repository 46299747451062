<!-- Mat Table to display attendance information -->
<div class="dialog-container">
  <button mat-icon-button class="close-button" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <!-- Period Column -->
    <ng-container matColumnDef="period">
      <th mat-header-cell *matHeaderCellDef> Period </th>
      <td mat-cell *matCellDef="let element"> {{ element.period }} </td>
    </ng-container>

    <!-- Present Column -->
    <ng-container matColumnDef="present">
      <th mat-header-cell *matHeaderCellDef> Present </th>
      <td mat-cell *matCellDef="let element"> {{ element.present }} </td>
    </ng-container>

    <!-- Absent Column -->
    <ng-container matColumnDef="absent">
      <th mat-header-cell *matHeaderCellDef> Absent </th>
      <td mat-cell *matCellDef="let element"> {{ element.absent }} </td>
    </ng-container>

    <!-- Leave Column -->
    <ng-container matColumnDef="leave">
      <th mat-header-cell *matHeaderCellDef> Leave </th>
      <td mat-cell *matCellDef="let element"> {{ element.leave }} </td>
    </ng-container>

    <!-- Table Rows -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
  </table>
</div>
