import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { App } from '@capacitor/app';
import { ActionPerformed, PushNotificationSchema, PushNotifications, Token } from '@capacitor/push-notifications';
import { StatusBar } from '@capacitor/status-bar';
import { getMessaging, getToken, onMessage } from '@firebase/messaging';
import { Platform } from '@ionic/angular';
import * as packageJson from '../../package.json';
import { environment } from '../environments/environment';
import { ApiService } from './api.service';
import { ForceupdateComponent } from './shared/modules/authentication/forceupdate/forceupdate.component';
import { CommonService } from './shared/services/common.service';
import { AuthService } from './shared/services/auth.service';

@Component({
  selector: 'app-root',
  template: `
    <app-page-loader></app-page-loader>
    <router-outlet></router-outlet>
  `
})
export class AppComponent implements OnInit {
  currentUrl: string;
  message: any = null;
  version: any;
  lastActiveUrl = localStorage.getItem('lastActiveUrl');
  history: any = [];

  constructor(public router: Router, public dialog: MatDialog, public platform: Platform, private common: CommonService, private apiService: ApiService, private location: Location,
    private authService: AuthService
  ) {
    const packageJsonData = packageJson;
    this.version = packageJsonData.version;
    this.router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        this.currentUrl = routerEvent.url.substring(routerEvent.url.lastIndexOf('/') + 1);
      }
      if (routerEvent instanceof NavigationEnd) {
        localStorage.setItem('lastActiveUrl', routerEvent.url);
        this.history.push(routerEvent.urlAfterRedirects);
      }
      window.scrollTo(0, 0);
    });
  }

  ngOnInit(): void {
    this.initializeApp();
    window.Notification.requestPermission().then(() => this.common.setPermission(true));
    this.requestPermission();
    this.listen();
    if (this.lastActiveUrl) {
      this.router.navigateByUrl(this.lastActiveUrl);
    }
    window.addEventListener("offline", () => {
      this.apiService.showNotification('snackbar-info', 'You are offline now.', 3000);
    });
    window.addEventListener("online", () => {
      this.apiService.showNotification('snackbar-success', 'Back to online.', 3000);
    });
  }

  initializeApp(): void {
    this.platform.ready().then(() => {
      if (this.platform.is('capacitor')) {
        this.initFCMPushNotification();
        this.getVersion();
        this.addBackButtonListener()
        this.showStatusBar();
      }
    });
  }

  showStatusBar(): void {
    StatusBar.show();
  }

  navigateBack(): void {
    const validPaths: Array<string> = ['/teacher/dashboard', '/admin/dashboard/main', '/student/dashboard', '/authentication/signin'];
    if (this.history.length && !validPaths.includes(this.history[this.history.length - 1])) {
      this.history.pop();
      this.location.back();
    } else {
      this.history = [];
      App.exitApp();
    }
  }

  addBackButtonListener(): void {
    document.addEventListener('backbutton', this.navigateBack.bind(this));
  }

  getVersion(): void {
    this.apiService.get('api/logininfo/version').subscribe((data: any) => {
      if (data && this.version < data[0]?.version && (this.platform.is('android') && data[0]?.android?.data[0] == 1)) {
        this.dialog.open(ForceupdateComponent, { disableClose: true });
      } else if (data && (this.platform.is('ios') && data[0]?.ios?.data[0] == 1)) {
        this.logout()
        this.dialog.open(ForceupdateComponent, { disableClose: true });
      }
    });
  }

  logout(): void {
    this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(['/authentication/signin']);
      }
    });
    this.authService.clearLocalStorageItems();
  }

  initFCMPushNotification(): void {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });
    PushNotifications.addListener('registration', (token: Token) => {
      localStorage.setItem('firebaseToken', token.value);
      console.log('Push registration success, token: ' + token.value);
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error on registration: ' + error);
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        // console.log('Push received: ' + notification);
      },
    );
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        // console.log('Push action performed: ' + notification);
      },
    );
  }

  requestPermission(): void {
    const messaging = getMessaging();
    getToken(messaging, { vapidKey: environment.firebase.vapidKey }).then((currentToken) => {
      this.common.setPermission(false);
      console.log('Firebase token:', currentToken);
      if (currentToken) {
        localStorage.setItem('firebaseToken', currentToken);
      }
    }).catch((error) => {
      console.log('Error getting Firebase token:', error);
      // Handle error
    });
  }

  listen(): void {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      this.message = payload;
    });
  }

  showAlert(message: any, callback: any): void {
    alert(message);
    if (callback && typeof callback === 'function') {
      callback();
    }
  }
}
