import { Component, Input } from "@angular/core";
import { AuthService } from "../../services/auth.service";
@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"],
})
export class BreadcrumbComponent {
  @Input() title: string;
  @Input() items: any;
  @Input() active_item: string;
  role: any;
  routingUrl: string;
  constructor(private authService: AuthService) {
    this.role = this.authService.currentUserValue.role;
    if (this.role === 'Admin') {
      this.routingUrl = '/admin/dashboard/main';
    } else if (this.role === 'Teacher') {
      this.routingUrl = '/teacher/dashboard';
    } else if (this.role === 'Student') {
      this.routingUrl = '/student/dashboard';
    }
  }
}
