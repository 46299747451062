// number-to-words.pipe.ts

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'numberToWords' })
export class NumberToWordsPipe implements PipeTransform {
  transform(value: number): string {
    if (value === 0) {
      return 'zero';
    }
    const units = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
    const teens = ['eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
    const tens = ['', 'ten', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

    function convert(num: number): string {
      if (num < 10) {
        return units[num];
      } else if (num < 20) {
        return teens[num - 11];
      } else if (num < 100) {
        return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? ' ' + units[num % 10] : '');
      } else {
        return units[Math.floor(num / 100)] + ' hundred' + (num % 100 !== 0 ? ' and ' + convert(num % 100) : '');
      }
    }
    return convert(value);
  }

}
