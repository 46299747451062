<nav #navbar class="navbar active">
  <div class="container-fluid">
    <div class="navbar-header">
      <a href="#" onClick="return false;" class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false" (mouseleave)="mouseOut($event)"></a>
      <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <a class="navbar-brand" routerLink={{homePage}}>
        <img [src]="logoImage | defaultimage" id="logo-image" alt="" />
        <span class="logo-name" [title]="schoolName">{{schoolName}}</span>
      </a>
    </div>
    <div class="collapse navbar-collapse header-submenu" [ngClass]="isNavbarCollapsed ? '' : 'show'"
      (mouseleave)="mouseOut($event)">
      <ul class="float-start collapse-menu-icon">
        <li>
          <button mat-button (click)="callSideMenuCollapse()" class="sidemenu-collapse nav-notification-icons">
            <i class="material-icons-outlined icon-color">menu</i>
          </button>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <li>
          <div class="academic-year">
            <span>{{ 'TIME-TABLE.ACADEMIC-YEAR' | translate }}</span>
            <ejs-dropdownlist [dataSource]="academic_years" [fields]="ddFields" width="160px" [value]="selectedAcademic"
              (change)="onAcademicYearChange($event)"></ejs-dropdownlist>
          </div>
        </li>
        <li *ngIf="userType === 'Student' && entityType === 'College'">
          <div class="academic-year">
            <span>{{ 'MENUITEMS.CLASS.TEXT' | translate }}</span>
            <ejs-dropdownlist [dataSource]="classes" [fields]="ccFields" width="160px" [value]="selectedClass"
              (change)="onClassChange($event)"></ejs-dropdownlist>
          </div>
        </li>
        <li class="direction-switcher" title="Change Direction">
          <button mat-button class="nav-notification-icons" (click)="switchDirection()">
            <i class="material-icons-outlined icon-color">
              {{ document.documentElement.hasAttribute('dir') ? 'format_textdirection_r_to_l' :
              'format_textdirection_l_to_r' }}
            </i>
          </button>
        </li>
        <li class="theme-switcher" title="Change Theme">
          <button mat-button class="nav-notification-icons" (click)="setTheme(!isDarkTheme)">
            <i class="material-icons-outlined icon-color">
              {{ isDarkTheme ? 'dark_mode' : 'light_mode' }}
            </i>
          </button>
        </li>
        <li class="color-settings" title="Settings">
          <button mat-button [matMenuTriggerFor]="settingsMenu" class="lang-dropdown nav-notification-icons">
            <i class="material-icons-outlined icon-color">settings</i>
          </button>
          <mat-menu #settingsMenu="matMenu">
            <div mat-menu-group>
              <div class="menu-group-header">{{ 'PROFILE-MENU.SETTINGS.SIDEBAR-COLOR' | translate }}</div>
              <button mat-menu-item *ngFor="let theme of themes" (click)="setEntityThemeColour(theme, true)">
                <mat-icon>{{theme.iconName}}</mat-icon>
                <span class="align-middle">{{theme.name}}</span>
              </button>
            </div>
            <div mat-menu-group>
              <div class="menu-group-header">{{ 'PROFILE-MENU.SETTINGS.HEADER-COLOR' | translate }}</div>
              <button mat-menu-item *ngFor="let color of colors" (click)="setEntityThemeColour(color, false)">
                <div class="header-color {{color.color}}"></div>
                <span class="align-middle">{{color.name}}</span>
              </button>
            </div>
          </mat-menu>
        </li>
        <li class="fullscreen" [title]="isFullScreen ? 'Exit fullscreen' : 'Fullscreen'">
          <button mat-button class="nav-notification-icons" (click)="callFullscreen()">
            <i class="material-icons-outlined icon-color">{{ isFullScreen ? "fullscreen_exit" : "fullscreen" }}</i>
          </button>
        </li>
        <li title="Notifications">
          <button mat-button [matMenuTriggerFor]="notificationMenu" class="nav-notification-icons">
            <i class="material-icons-outlined icon-color">notifications_active</i>
          </button>
          <span class="notification-count" *ngIf="notificationCount > 0">
            <sup>{{ notificationCount }}</sup>
          </span>
          <mat-menu #notificationMenu="matMenu" class="nfc-menu" xPosition="after">
            <div class="nfc-header">
              <h5 class="mb-0">{{ 'PROFILE-MENU.NOTIFICATIONS.TEXT' | translate }}</h5>
              <a class="nfc-mark-as-read mark-all-btn" (click)="markAllAsRead()">{{ 'PROFILE-MENU.NOTIFICATIONS.MARK-ALL' | translate }}</a>
            </div>
            <div class="nfc-dropdown">
              <ng-scrollbar style="height: 350px" visibility="hover">
                <div class="noti-list header-menu">
                  <div class="menu">
                    <div>
                      <button mat-menu-item *ngFor="let notification of notifications" class="nfc-allign" onClick="return false;">
                        <span class="table-img msg-user ">
                          <i class="material-icons-two-tone nfc-type-icon">notifications</i>
                        </span>
                        <span class="menu-info">
                          <span class="menu-title">{{notification.message}}</span>
                          <span class="menu-desc mt-2">
                            <i class="material-icons">access_time</i>
                            {{ datemethod(notification.created_time) | dateAgo }}
                          </span>
                        </span>
                        <span class="nfc-close" (click)="markAsRead(notification)">
                          <button class="invisible-button" (click)="markAsRead(notification)">
                            <app-feather-icons [icon]="'x'" [class]="'user-menu-icons'"></app-feather-icons>
                          </button>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </ng-scrollbar>
            </div>
            <div class="nfc-footer">
              <a class="nfc-read-all" (click)="markAllAsRead()">Read All Notifications</a>
            </div>
          </mat-menu>
        </li>
        <li class="nav-item" *ngIf="userType === 'Student'" title="Change Language">
          <button mat-button [matMenuTriggerFor]="languageMenu" class="lang-dropdown nav-notification-icons">
            <i class="material-icons-outlined icon-color">language</i>
          </button>
          <mat-menu #languageMenu="matMenu">
            <div *ngFor="let item of listLang" class="lang-item">
              <button mat-menu-item class="dropdown-item lang-item-list"
                (click)="setLanguage(item.text, item.lang, item.flag)"
                [ngClass]="{'active': langStoreValue === item.lang}">
                <span class="align-middle">{{item.text}}</span>
              </button>
            </div>
          </mat-menu>
        </li>
        <li class="nav-item user_profile">
          <button mat-button [matMenuTriggerFor]="profileMenu">
            <div class="chip dropdown-toggle" ngbDropdownToggle class="pt-0">
              <img [src]="userImg | defaultimage" class="user_img" width="32" height="32" alt="User">
              <span>{{userName}}</span>
            </div>
          </button>
          <mat-menu #profileMenu="matMenu" class="profile-menu">
            <div class="noti-list">
              <div class="menu ">
                <div class="user_dw_menu">
                  <button mat-menu-item (click)="profileInfo()">
                    <div class="menu-items">
                      <i class="material-icons-outlined icon-color">person</i>
                      <span>{{ 'PROFILE-MENU.ACCOUNT' | translate }}</span>
                    </div>
                  </button>
                  <button mat-menu-item (click)="school()" *ngIf="department_name==='super admin'">
                    <div class="menu-items">
                      <i class="material-icons-outlined icon-color">school</i>
                      <span>{{'School' | schooltocollege}}</span>
                    </div>
                  </button>
                  <button mat-menu-item (click)="updateBoardingLocation()" *ngIf="userType === 'Student'">
                    <div class="menu-items">
                      <i class="material-icons-outlined icon-color">location_on</i>
                      <span>{{ 'PROFILE-MENU.BOARDING-POINT' | translate }}</span>
                    </div>
                  </button>
                  <button mat-menu-item (click)="logout()">
                    <div class="menu-items">
                      <i class="material-icons-outlined icon-color">logout</i>
                      <span>{{ 'PROFILE-MENU.LOGOUT' | translate }}</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </mat-menu>
        </li>
      </ul>
    </div>
  </div>
</nav>
