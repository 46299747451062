import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable, catchError, throwError } from "rxjs";
import { environment } from "../environments/environment";

@Injectable({ providedIn: "root" })
export class ApiService {
  private serverUrl = environment.apiUrl;

  constructor(private http: HttpClient, private snackBar: MatSnackBar) { }

  private createUrl(endpoint: string): string {
    return this.serverUrl + endpoint;
  }

  /** HTTP request to get data */
  public get(endpoint: string, params: any = {}): Observable<any> {
    const url: string = this.createUrl(endpoint);
    return this.http.get(url, params).pipe(catchError(this.handleError));
  }

  /** HTTP request to add new data */
  public post(endpoint: string, data: any): Observable<any> {
    const url: string = this.createUrl(endpoint);
    return this.http.post(url, data).pipe(catchError(this.handleError));
  }

  /** HTTP request to update existing data */
  public put(endpoint: string, id: number, data?: any): Observable<any> {
    let url: string = this.createUrl(endpoint);
    url = id ? `${url}/${id}` : url;
    return this.http.put(url, data).pipe(catchError(this.handleError));
  }

  public delete(endpoint: string, options?: any): Observable<any> {
    const url: string = this.createUrl(endpoint);
    return this.http.delete(url, options).pipe(catchError(this.handleError));
  }

  /** Method to handle HTTP request/response errors  */
  private handleError(error: HttpErrorResponse): Observable<ArrayBuffer> {
    if (error.status === 400) {
      return throwError(() => error);
    }
    return throwError(() => (error.status === 401) ? 'Session Expired' : 'Something error occurred, please try again later.');
  }

  showNotification(colorName: any, text: any, duration?: number) {
    this.snackBar.open(text, '', {
      duration: duration || 2000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      panelClass: colorName,
    });
  }
}
