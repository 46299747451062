import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';
import { AuthService } from '../../services/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private authenticationService: AuthService, private apiService: ApiService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (!navigator.onLine) {
          this.apiService.showNotification('snackbar-info', 'Please check your internet connection.!');
          return EMPTY;
        }
        if (err.status === 401) {
          this.authenticationService.logout();
          location.reload();
        }
        const error = err.status === 400 ? err : (err?.error?.message || err.statusText);
        return throwError(() => error);
      })
    );
  }
}
