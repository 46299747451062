<div class="container" *ngIf="carouselList?.length">
  <ejs-dialog id='carousel-dialog' #template showCloseIcon='true' isModal="true" visible="true" height="auto"
    width="400">
    <ng-template #content>
      <div class="dialogContent">
        <div class="control-section db-carousel-section">
          <div class="col-lg-12 content-wrapper">
            <div class="carousel-sample">
              <ejs-carousel cssClass="db-carousel" [dataSource]="carouselList" [autoPlay]="false" (slideChanged)="onSlideChanged($event)" >
                <ng-template #itemTemplate let-data >
                  <div class="card" (click)="redirectToPage(data)">

                    <div class="card-body" style="padding:1rem; overflow: auto;">
                      <h5 class="card-title">{{data.title}}</h5>
                      <p class="card-text" [innerHTML]="data.content"></p>
                    </div>
                  </div>
                </ng-template>
              </ejs-carousel>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #header>
      <div>Event & noticeboard</div>
    </ng-template>
  </ejs-dialog>
</div>
