import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { CarouselAllModule } from '@syncfusion/ej2-angular-navigations';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { initializeApp } from 'firebase/app';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CarouselPopupComponent } from './shared/components/carousel-popup/carousel-popup.component';
import { CoreModule } from './shared/modules/core.module';
import { ErrorInterceptor } from './shared/modules/interceptor/error.interceptor';
import { JwtInterceptor } from './shared/modules/interceptor/jwt.interceptor';
import { AuthLayoutComponent } from './shared/modules/layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './shared/modules/layout/app-layout/main-layout/main-layout.component';
import { HeaderComponent } from './shared/modules/layout/header/header.component';
import { PageLoaderComponent } from './shared/modules/layout/page-loader/page-loader.component';
import { RightSidebarComponent } from './shared/modules/layout/right-sidebar/right-sidebar.component';
import { SidebarComponent } from './shared/modules/layout/sidebar/sidebar.component';
import { DateAgoPipe } from './shared/pipes/date-ago.pipe';
import { SharedModule } from './shared/shared.module';
import { MatTableModule } from '@angular/material/table';
import { AttendanceInfoDialogComponent } from './admin/modules/students/college-attendance-new/attendance-info-dialog/attendance-info.component';

initializeApp(environment.firebase);

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    PageLoaderComponent,
    SidebarComponent,
    RightSidebarComponent,
    AuthLayoutComponent,
    MainLayoutComponent,
    CarouselPopupComponent,
    DateAgoPipe,
    AttendanceInfoDialogComponent,
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgScrollbarModule,
    ClickOutsideModule,
    DigitOnlyModule,
    NgxMatTimepickerModule,
    MatTableModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    LoadingBarRouterModule,
    CoreModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    SharedModule,
    DropDownListAllModule,
    DialogModule,
    CarouselAllModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
