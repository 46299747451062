import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { DirectionService } from '../services/direction.service';
import { DynamicScriptLoaderService } from '../services/dynamic-script-loader.service';
import { RightSidebarService } from '../services/rightsidebar.service';
import { AuthGuard } from './guard/auth.guard';

@NgModule({
  imports: [CommonModule],
  providers: [
    RightSidebarService,
    AuthGuard,
    AuthService,
    DynamicScriptLoaderService,
    DirectionService,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(`CoreModule has already been loaded. Import CoreModule modules in the AppModule only.`);
    }
  }
}
