import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AskQueryComponent } from './shared/components/ask-query/ask-query.component';
import { Role } from './shared/models/role';
import { Page404Component } from './shared/modules/authentication/page404/page404.component';
import { AuthGuard } from './shared/modules/guard/auth.guard';
import { AuthLayoutComponent } from './shared/modules/layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './shared/modules/layout/app-layout/main-layout/main-layout.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'admin',
        canActivate: [AuthGuard],
        data: { role: Role.Admin },
        loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: 'teacher',
        canActivate: [AuthGuard],
        data: { role: Role.Teacher },
        loadChildren: () => import('./teacher/teacher.module').then((m) => m.TeacherModule),
      },
      {
        path: 'student',
        canActivate: [AuthGuard],
        data: { role: Role.Student },
        loadChildren: () => import('./student/student.module').then((m) => m.StudentModule),
      },
      { path: '', canActivate: [AuthGuard] },
      { path: 'chat', component: AskQueryComponent, canActivate: [AuthGuard] },
    ],
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () => import('./shared/modules/authentication/authentication.module').then((m) => m.AuthenticationModule),
  },
  { path: '**', component: Page404Component },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
