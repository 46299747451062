import { Component, ViewChild, ElementRef } from '@angular/core';
import { Configuration, OpenAIApi } from 'openai';
import { environment } from 'src/environments/environment';
import { ChatWithBot, ResponseModel } from '../../models/gpt-response';

@Component({
  selector: 'app-ask-query',
  templateUrl: './ask-query.component.html',
  styleUrls: ['./ask-query.component.scss'],
})
export class AskQueryComponent {
  chatConversation: ChatWithBot[] = [];
  @ViewChild('chatMessages') chatMessages: ElementRef;
  response!: ResponseModel | undefined;
  promptText = '';
  showSpinner = false;
  breadscrums: any[] = [
    {
      title: 'Ask Me',
      items: [],
      active: 'Chat',
    },
  ];

  checkResponse() {
    this.pushChatContent(this.promptText, 'you', 'person');
    this.invokeGPT();
    this.promptText = '';
    setTimeout(() => this.scrollToBottom());
  }

  getText(data: string) {
    return data.split('\n').filter((f) => f.length > 0);
  }

  pushChatContent(content: string, person: string, cssClass: string) {
    const chatToPush: ChatWithBot = {
      person: person,
      response: content,
      cssClass: cssClass,
    };
    this.chatConversation.push(chatToPush);
  }

  async invokeGPT() {
    if (this.promptText.length < 2) return;

    try {
      this.response = undefined;
      const configuration = new Configuration({
        apiKey: environment['apiKey'],
      });
      const openai = new OpenAIApi(configuration);

      const requestData = {
        model: 'gpt-3.5-turbo',
        prompt: this.promptText,
        temperature: 0.95,
        max_tokens: 150,
        top_p: 1.0,
        frequency_penalty: 0.0,
        presence_penalty: 0.0,
      };
      this.showSpinner = true;
      const apiResponse = await openai.createCompletion(requestData);

      this.response = apiResponse.data as ResponseModel;
      const trimContent = this.response.choices[0].text.trim();
      this.pushChatContent(trimContent, 'bot', 'bot');
      this.showSpinner = false;
      setTimeout(() => this.scrollToBottom());
    } catch (error: any) {
      this.showSpinner = false;
      this.pushChatContent(
        "Sorry We Couldn't Process the request! Try later",
        'bot',
        'bot'
      );
    }
  }
  scrollToBottom() {
    const element = this.chatMessages.nativeElement;
    element.scrollTop = element.scrollHeight + 30;
  }
  onCopy(event: any) {
    const currentCopyEle = event.target;
    const messageEle = currentCopyEle.closest('.message');
    const range = document.createRange();
    range.selectNode(messageEle);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    currentCopyEle.closest('.copy-btn').querySelector('.mdc-button__label').innerText = 'Copied!';
    setTimeout(() => {
      currentCopyEle.closest('.copy-btn').querySelector('.mdc-button__label').innerText = 'Copy';
    }, 2000);
  }
}
