import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { ApiService } from 'src/app/api.service';
import { AuthService } from '../../services/auth.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Geolocation } from '@capacitor/geolocation';
import { Platform } from '@ionic/angular';
import { CommonService } from '../../services/common.service';
@Component({
  selector: 'app-location-selector',
  templateUrl: './location-selector.component.html',
  styleUrls: ['./location-selector.component.scss']
})
export class LocationSelectorComponent implements OnInit {
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap | any;
  zoom = 15;
  center: google.maps.LatLngLiteral | any = {
  };
  options: google.maps.MapOptions | any = {
    mapTypeId: 'roadmap',
  };
  markers: any = {};
  address: any;
  constructor(private service: ApiService,
    private authservice: AuthService,
    private platform: Platform,
    public dialogRef: MatDialogRef<LocationSelectorComponent>,
    private ngZone: NgZone,
    private commonservice: CommonService) {
  }

  ngOnInit(): void {

    this.platform.ready().then(() => {
      if (this.platform.is('capacitor')) {
        this.setCurrentPosition();
      }
    });

    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
    });
  }

  async setCurrentPosition() {
    const coordinates = await Geolocation.getCurrentPosition();
    this.ngZone.run(() => {
      this.center = {
        lat: coordinates.coords.latitude,
        lng: coordinates.coords.longitude,
      };
    })
  }


  eventHandler(event: any, name: string) {
    this.dropMarker(event)
    this.getGeolcation(event.latLng.lat(), event.latLng.lng())
  }

  dropMarker(event: any) {
    this.markers = {
      position: {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      },
    };
  }

  getGeolcation(latitude: any, longitude: any) {
    const params: any = {
      lat: latitude,
      lng: longitude
    }
    this.commonservice.getGeolcation(params).subscribe(
      (data: any) => {
        if (data != null) {
          this.address = data.results[0].formatted_address;
        }
      },
      (error: any) => {
        /** */
      }
    )
  }


  openInfoWindow(marker: MapMarker) {
    this.infoWindow.open(marker);
  }

  confirmAdd() {
    const body: any = {
      school_id: this.authservice.currentUserValue.school_id,
      student_id: this.authservice.currentUserValue.id,
      latitude: this.markers.position.lat,
      longitude: this.markers.position.lng
    }
    this.service.post('api/businfo/studentboardinglocation', body).subscribe((data: any) => {
      if (data != null) {
        this.service.showNotification(
          'snackbar-success',
          'Location Added Successfully',
        );
        this.dialogRef.close();
      }
    }, (error: any) => {
      this.service.showNotification(
        'snackbar-danger',
        'Error!!!',
      );
    });
  }

}
